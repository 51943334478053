import {defineStore} from "pinia";

export const useApplicationStore = defineStore('applicationStore', {
    state: () => ({
        isDarkMode: false,
    }),
    actions: {
        initDarkMode() {
            if(localStorage.getItem('isDarkMode') !== null) {
                this.isDarkMode = localStorage.getItem('isDarkMode') === 'true';
            }
            this.updateDarkModeClass();
        },
        toggleDarkMode() {
            this.isDarkMode = !this.isDarkMode;
            localStorage.setItem('isDarkMode', this.isDarkMode);
            this.updateDarkModeClass();
        },
        updateDarkModeClass() {
            if(this.isDarkMode) {
                document.documentElement.classList.remove('light');
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
                document.documentElement.classList.add('light');
            }
        }
    },
    persist: {
        enabled: true
    },
});
